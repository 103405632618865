import { gql, useQuery } from '@apollo/client'
import { Button, CardActions } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Link } from 'gatsby'
import { forwardRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { QAContent, QAHeader, QAPanel } from '@karakuri-ai/faq-component'

import { Breadcrumb, Hierarchy, TagCloud, TagData, TagPageContext } from './interface'

function generateGAQuery(pageContext: TagPageContext) {
  const categoryId = pageContext.parentId || pageContext.id
  const categoryName = pageContext.parentTitle || pageContext.title
  const subCategoryId = pageContext.parentId ? pageContext.id : null
  const subCategoryName = pageContext.parentTitle ? pageContext.title : null

  return `?category_id=${encodeURIComponent(categoryId)}&category_name=${encodeURIComponent(
    categoryName
  )}${subCategoryId ? `&sub_category_id=${encodeURIComponent(subCategoryId)}` : ''}${
    subCategoryName ? `&sub_category_name=${encodeURIComponent(subCategoryName)}` : ''
  }`
}

const CATEGORY_FAQ = gql`
  query faqByCategory($botId: ID!, $categories: [ID!]!, $tenantId: String) {
    filterByCategory(botId: $botId, categories: $categories, tenantId: $tenantId) {
      id
      title
    }
  }
`
interface Props {
  breadcrumb: Breadcrumb
  data: TagData
  tagCloud: TagCloud
  pageContext: TagPageContext
  hierarchies: Hierarchy[]
}
export function TagWidget({ data, pageContext, hierarchies }: Props) {
  const { botId, tenantId } = data.settingYaml
  const [page, setPage] = useState(1)
  const { loading, data: faqByCategory } = useQuery<{
    filterByCategory: { id: string; title: string }[]
  }>(CATEGORY_FAQ, {
    variables: {
      botId,
      categories: [pageContext.id, ...(pageContext.children || [])].filter(x => x),
      tenantId,
    },
  })
  const handleMoreClick = () => setPage(page + 1)
  const totalCount = faqByCategory?.filterByCategory?.length || 0
  const perPage = 5

  const maxLoadingCount = page * perPage
  const hasNext = maxLoadingCount < totalCount

  if (
    loading ||
    !faqByCategory ||
    !faqByCategory.filterByCategory ||
    faqByCategory.filterByCategory.length === 0
  ) {
    return null
  }
  return (
    <QAPanel>
      <QAHeader
        title={`${totalCount}件中 1〜${
          maxLoadingCount < totalCount ? maxLoadingCount : totalCount
        }件`}
        titleClass="faq-pagination"
      />
      <InfiniteScroll
        pageStart={0}
        hasMore={hasNext}
        loader={null as any}
        loadMore={handleMoreClick}
      >
        {faqByCategory.filterByCategory.map(({ title, id }, index) => {
          if (maxLoadingCount - 1 < index) {
            return null
          }
          const h = [
            ...hierarchies,
            { title: pageContext.title, link: `/category/${pageContext.id}` },
          ]
          const LinkComponent = forwardRef(function LinkComponent(props, ref) {
            const query = generateGAQuery(pageContext)
            return (
              <Link
                ref={ref as any}
                {...props}
                to={`/answer/${id}${query}`}
                state={{ hierarchies: h }}
              />
            )
          })
          return <QAContent key={id} question={title || ''} LinkComponent={LinkComponent} />
        })}
      </InfiniteScroll>

      {hasNext && (
        <CardActions>
          <Button
            className="faq-button faq-button-more"
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleMoreClick}
          >
            もっと見る
            <ExpandMoreIcon className="faq-button-icon" />
          </Button>
        </CardActions>
      )}
    </QAPanel>
  )
}
