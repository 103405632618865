import { navigate } from 'gatsby'

import { QACategory, QAHeader, QAPanel, values } from '@karakuri-ai/faq-component'

import { SettingYaml, SettingYamlCategorySub } from '../graphql'

import { CategoryLayoutProps } from './interface'

interface Props {
  layout: CategoryLayoutProps
  data: {
    settingYaml: SettingYaml
  }
}
export function CategoryWidget({ layout, data }: Props) {
  const categories = values(data.settingYaml.categories, [])
  if (!categories || categories.length === 0) {
    return null
  }
  const hashId =
    typeof window !== 'undefined' &&
    !!window.location &&
    !!window.location.pathname &&
    window.location.pathname.substring(10)
  const expandable = (c: SettingYamlCategorySub | null): boolean =>
    hashId === c?.id || (c?.sub || []).some(s => expandable(s))
  const handleCategoryClick = (path: string) =>
    navigate(path.indexOf('category/') === 0 ? `/${path}` : path)

  return (
    <QAPanel className="faq-panel-category">
      <QAHeader title={layout.title || 'カテゴリから探す'} />
      {categories.map(category => (
        <QACategory
          key={category.id || ''}
          id={category.id || ''}
          title={category.title}
          sub={category.sub}
          expand={expandable(category)}
          checker={expandable}
          onCategoryClick={handleCategoryClick}
        />
      ))}
    </QAPanel>
  )
}
